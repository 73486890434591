<template>
  <award-card>
    <template #search>
      <el-row :gutter="12">
        <el-col :span="12">
          <el-date-picker v-model="time" type="daterange" :size="$publicConfig.selectSize" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-select :size="$publicConfig.selectSize" v-model="search.gradeCode" placeholder="请选择年级" @change="() => {
              search.classCode = ''
              cardStatStudentAll()
            }">
            <el-option v-for="item in allGrade" :key="item.gradeCode" :label="item.gradeName" :value="item.gradeCode"></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select :size="$publicConfig.selectSize" v-model="search.classCode" placeholder="请选择班级" @change="cardStatStudentAll">
            <el-option v-for="item in allClass" :key="item.classCode" :label="item.className" :value="item.classCode"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <template #buttons>
      <el-button type="primary" :size="$publicConfig.buttonSize" @click="goto">大屏统计</el-button>
      <el-button type="primary" :size="$publicConfig.buttonSize" icon="el-icon-download" @click="downloadFile">导出结果</el-button>
    </template>
    <el-table stripe :data="tableData" style="width: 100%" :height="height" @sort-change="sortChange" v-if="tableData.length > 0">
      <el-table-column fixed prop="date" label="排名" sortable="custom" width="90">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column fixed prop="class_name" label="班级"> </el-table-column>
      <el-table-column fixed prop="student_name" label="姓名"> </el-table-column>
      <el-table-column fixed prop="cnt" label="卡片数量"> </el-table-column>
      <el-table-column fixed prop="address" :label="v.name + '类卡'" v-for="(v, k) in cards" :key="k">
        <template slot-scope="scope">
          {{ scope.row['field_' + v.id] || 0 }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="view(scope.row)" type="text" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="search.size" @current-change="currentChange" :hide-on-single-page="false">
      </el-pagination>
    </div>
    <el-dialog :title="records.name + '集卡记录'" :visible.sync="dialogTableVisible">
      <el-table stripe :data="records.record" height="300">
        <el-table-column property="date" label="集卡时间">
          <template slot-scope="scope">
            {{ $cftY(scope.row.receivedAt) }}
          </template>
        </el-table-column>
        <el-table-column property="name" label="卡片类型"></el-table-column>
        <el-table-column property="teacherName" label="发卡老师"></el-table-column>
      </el-table>
    </el-dialog>
  </award-card>
</template>

<script>
import { cardStatStudentAll, studentHistory } from "@/http/daping";
import { mapGetters } from "vuex";
import { getCardConfigList, exportStudent } from "@/http/awardCard";

export default {
  data() {
    return {
      value: "",
      // allClass: [],
      tableData: [],
      records: {
        name: "",
        record: [],
      },
      dialogTableVisible: false,
      height: 100,
      time: [],
      search: {
        page: 1,
        size: 20,
        sort: 0,
        gradeCode: "",
        classCode: "",
        time0: "",
        time1: "",
      },
      cards: [],
      total: 0,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "allNGrade", "allNClass"]),
    allGrade() {
      console.log("2222", this.allNGrade, this.allNClass);
      return [
        {
          gradeCode: "",
          gradeName: "全部年级",
        },
        ...this.allNGrade,
      ];
    },
    allClass() {
      if (this.search.gradeCode == "") {
        return [
          {
            className: "全部班级",
            classCode: "",
          },
        ];
      }
      return [
        {
          className: "全部班级",
          classCode: "",
        },
        ...this.allNClass
          .filter((item) => item.gradeInfo.gradeCode == this.search.gradeCode)
          .map((item) => {
            return {
              className: item.className,
              classCode: item.classCode,
            };
          }),
      ];
    },
  },
  created() {
    this.height = document.body.clientHeight - 314;
    this.cardStatStudentAll();
    this.getCardConfigList();
    this.init();
  },
  methods: {
    view(row) {
      studentHistory({
        studentCode: row.student_id,
        schoolId: this.userInfo.tnId,
      }).then((res) => {
        this.dialogTableVisible = true;
        this.records.name = row.student_name;
        this.records.record = res.data;
      });
    },
    dateChange(e) {
      this.search.time0 = new Date(e[0]).getTime();
      this.search.time1 = new Date(e[1]).getTime();
      this.cardStatStudentAll();
    },
    init() {},
    getCardConfigList() {
      getCardConfigList(this.userInfo.tnId).then((res) => {
        if (res.retCode == 1) {
          this.cards = res.data;
        }
      });
    },
    currentChange(e) {
      this.search.page = e;
      this.cardStatTeacherAll();
    },
    cardStatStudentAll() {
      let params = {
        schoolId: this.userInfo.tnId,
        ...this.search,
      };
      cardStatStudentAll(params).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    sortChange() {
      this.search.sort = this.search.sort == 0 ? 1 : 0;
    },
    goto() {
      this.$router.push({
        path: "/daping",
      });
      // window.open(routeData.href, "_blank");
    },
    downloadFile() {
      this.myLoading = this.$loading({
        lock: true,
        text: "导出中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      exportStudent({
        schoolId: this.userInfo.tnId,
        ...this.search,
      }).then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "学生统计结果" + this.$cftY() + ".xlsx";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
        this.myLoading.close();
        this.$message.success("导出成功");
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.pagination {
  width: 100%;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>